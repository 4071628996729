import React, { useState } from "react";
import formatDateTime, {
  findHours,
  formatDateOnly,
} from "../../../../helpers/utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfSvg from "../../../../assets/Pdf.svg";
import MonthlyInvoiceTemplate from "./monthlyInvoiceTemplate";

const MonthlyInvoices = (props) => {
  const renderInvoices = (invoices) => (
    <div className="accordion">
      {invoices.map((invoice, index) => (
        <div key={index} className="card mb-2">
          <div
            onClick={() => toggleAccordion(index)}
            className="card-header cursor-pointer flex justify-between items-center rounded-md bg-gray-200 px-4 py-2"
          >
            <span className="text-lg font-semibold">{invoice.month}</span>
            <div className="accordion-button flex items-center">
              {activeKeys.includes(index) ? (
                <>
                  <svg
                    className="ml-2"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="19" x2="12" y2="5"></line>
                    <polyline points="5 12 12 5 19 12"></polyline>
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    className="ml-2"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                  </svg>
                </>
              )}
            </div>
          </div>
          <div
            className={`accordion-collapse ${
              activeKeys.includes(index) ? "show" : "hidden"
            }`}
          >
            <div className="card-body bg-gray-50 rounded-b-md p-4">
              <div className="space-y-4 lg:block hidden">
                <div className="border rounded-md overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                          #
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                          Appointment Title
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                          Date
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                          Hours
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                          Hourly Rate
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                          Total
                        </td>
                      </tr>
                    </thead>
                    <tbody id="table-body" className="divide-y divide-gray-200">
                      {invoice.breakdown && invoice.breakdown.length === 0 ? (
                        <tr>
                          <td
                            colSpan="8"
                            className="px-6 py-3 text-center text-red-500"
                          >
                            No history found.
                          </td>
                        </tr>
                      ) : (
                        invoice.breakdown &&
                        invoice.breakdown.map((item, key) => (
                          <tr key={key}>
                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                              {key + 1}
                            </td>
                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                              {item.class_name}
                            </td>
                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                              {formatDateOnly(item.date)}
                            </td>
                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                              {item.duration / 60} Hrs
                            </td>
                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                              {parseFloat(invoice.hourly_rate).toFixed(2)}
                            </td>
                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                              Kr{" "}
                              {parseFloat(
                                invoice.hourly_rate * (item.duration / 60)
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="bg-gray-100 p-4 flex justify-between items-center">
                    <div className=" text-lg font-semibold mr-7 flex space-x-6">
                      <PDFDownloadLink
                        document={<MonthlyInvoiceTemplate invoice={invoice} />}
                        fileName={`${"Invoice"}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <img
                              className="w-8 h-8"
                              src={PdfSvg}
                              alt="pdf-svg"
                            />
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                    <div className=" text-lg font-semibold mr-7 flex space-x-6">
                      <p>Total Amount:</p>
                      <p> Kr {parseFloat(invoice.total_amount_due)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-4 lg:hidden block">
                {invoice.breakdown && invoice.breakdown.length === 0 ? (
                  <div>
                    <div className="px-6 py-3 text-center text-red-500">
                      No history found.
                    </div>
                  </div>
                ) : (
                  invoice.breakdown &&
                  invoice.breakdown.map((item, key) => (
                    <div className="border rounded-md p-3 shadow">
                      <div>
                        <div className="flex items-center justify-between py-2 border-b">
                          <span className="text-gray-500">
                            Appointment Title
                          </span>
                          <span className="font-semibold text-gray-500">
                            {item.class_name}
                          </span>
                        </div>
                        <div className="flex items-center justify-between py-2 border-b">
                          <span className="text-gray-500">Hrs Rate</span>
                          <span className="font-semibold text-gray-500">
                            {parseFloat(invoice.hourly_rate)}
                          </span>
                        </div>
                        <div className="flex items-center justify-between py-2 border-b">
                          <span className="text-gray-500">Hours</span>
                          <span className="font-semibold text-gray-500">
                            {item.duration / 60} Hrs
                          </span>
                        </div>
                        <div className="flex items-center justify-between py-2 border-b">
                          <span className="text-gray-500">Date:</span>
                          <span className="font-semibold text-gray-500">
                            {formatDateOnly(item?.date)}
                          </span>
                        </div>
                        <div className="flex items-center justify-between py-2 border-b">
                          <span className="text-gray-500">Total:</span>
                          <span className="font-semibold text-gray-500">
                            Kr
                            {parseFloat(
                              invoice.hourly_rate * (item.duration / 60)
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const [activeKeys, setActiveKeys] = useState([0]);

  const toggleAccordion = (id) => {
    setActiveKeys((prevKeys) =>
      prevKeys.includes(id)
        ? prevKeys.filter((key) => key !== id)
        : [...prevKeys, id]
    );
    console.log(activeKeys);
  };
  console.log(props);
  return (
    <div className="container mt-4">
      <div className="tab-content mt-3">
        {renderInvoices(props.monthlyInvoices)}
      </div>
    </div>
  );
};

export default MonthlyInvoices;
