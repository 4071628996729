const logout = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("email");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user_type");
  localStorage.removeItem("avatar");
  window.location.reload(true);
};

export default logout;
