import React, { useEffect, useState } from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import drivePro from "./../../../../assets/drivePro.jpeg";
import { TrimId, formatDateOnly } from "../../../../helpers/utils";
import { getClassDataOnIds } from "../../../../services/instructorService";

const MonthlyInvoiceTemplate = (props) => {
  const [invoiceData] = useState(props?.invoice);
  const [totalPrice, setTotalPrice] = useState(
    props?.invoice?.total_amount_due
  );
  const [percentagePrice, setPercentagePrice] = useState(
    props?.invoice?.total_amount_due * 0.25
  );
  console.log("invoiceData", invoiceData);
  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingHorizontal: 30,
          paddingVertical: 20,
          fontSize: "10",
          color: "#3F3F46",
          fontFamily: "Helvetica",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: "50%" }}>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text style={{ fontSize: 20, fontFamily: "Helvetica-Bold" }}>
                  Drive <Text style={{ color: "#3B82F6" }}>Pro</Text>
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text>Invoice Number</Text>
                <Text>#{TrimId(props?.invoice?._id)}</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text>Invoice Generation Date</Text>
                <Text>{formatDateOnly(props?.invoice?.createdAt)}</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text>Invoice Month</Text>
                <Text>{invoiceData?.month}</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text>Address</Text>
                <Text>{invoiceData?.instructor_data?.address}</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 0.5,
                  borderBottomColor: "#D4D4D8",
                  padding: 5,
                }}
              >
                <Text>Email</Text>
                <Text>{invoiceData?.instructor_data?.email}</Text>
              </View>
            </View>
          </View>
          <View style={{ width: "50%" }}>
            <View style={{ maxHeight: 180 }}>
              <Image
                src={drivePro}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </View>
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#3B82F6",
            color: "white",
            display: "flex",
            paddingHorizontal: 6,
            paddingVertical: 8,
            flexDirection: "row",
            marginTop: -30,
          }}
        >
          <Text style={{ width: "20%", fontFamily: "Helvetica-Bold" }}>
            Appointment Title
          </Text>
          <Text
            style={{
              width: "20%",
              textAlign: "center",
              fontFamily: "Helvetica-Bold",
            }}
          >
            Date
          </Text>
          <Text
            style={{
              width: "20%",
              textAlign: "center",
              fontFamily: "Helvetica-Bold",
            }}
          >
            Hour Rate
          </Text>
          <Text
            style={{
              width: "20%",
              textAlign: "center",
              fontFamily: "Helvetica-Bold",
            }}
          >
            Duration
          </Text>
          <Text
            style={{
              width: "20%",
              textAlign: "center",
              fontFamily: "Helvetica-Bold",
            }}
          >
            Total
          </Text>
        </View>
        {invoiceData?.breakdown.map((item, key) => (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: 0.5,
              borderBottomColor: "#D4D4D8",
              paddingHorizontal: 6,
              paddingVertical: 8,
            }}
          >
            <Text style={{ width: "20%" }}>{item?.class_name}</Text>
            <Text style={{ width: "20%", textAlign: "center" }}>
              {formatDateOnly(item?.date)}
            </Text>
            <Text style={{ width: "20%", textAlign: "center" }}>
              DKK {parseFloat(invoiceData?.hourly_rate).toFixed(2)}
            </Text>
            <Text style={{ width: "20%", textAlign: "center" }}>
              {item?.duration / 60} Hrs
            </Text>
            <Text style={{ width: "20%", textAlign: "center" }}>
              DKK {(item?.duration / 60) * invoiceData?.hourly_rate}
            </Text>
          </View>
        ))}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 30,
          }}
        >
          <View style={{ width: "50%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: 0.5,
                borderBottomColor: "#D4D4D8",
                paddingHorizontal: 6,
                paddingVertical: 8,
              }}
            >
              <Text>Amount excl. VAT</Text>
              <Text>
                DKK {parseFloat(totalPrice - percentagePrice)?.toFixed(2)}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: 0.5,
                borderBottomColor: "#D4D4D8",
                paddingHorizontal: 6,
                paddingVertical: 8,
              }}
            >
              <Text>25% moms</Text>
              <Text>DKK {parseFloat(percentagePrice)?.toFixed(2)}</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#3B82F6",
                color: "white",
                paddingHorizontal: 6,
                paddingVertical: 8,
                fontFamily: "Helvetica-Bold",
              }}
            >
              <Text>Total</Text>
              <Text>DKK {parseFloat(totalPrice)?.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MonthlyInvoiceTemplate;
