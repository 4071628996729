//Libraries
import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import notify from "../../../helpers/raiseToast";

import { useNavigate } from "react-router-dom";
//Components
import Modal from "../../../components/modal/Modal";
import { listColorCode } from "../../../constants";

const AppointmentModal = ({
  isAppointmentModalOpen,
  setIsAppointmentModalOpen,
  students,
  instructors,
  formik,
  isEditMode,
  classTypes,
  deleteAppointment,
  selectedAppointmentId,
  selectedDate,
  studentsToAdd,
  setStudentsToAdd,
  studentsToRemove,
  setStudentsToRemove,
  closeModal,
  isStudent,
  isInstructor,
  createAppointment,
  setAppointments,
  viewAllAppointments,
}) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const navigate = useNavigate();
  const isValidForm = (values) =>
    values.name.length > 0 &&
    values.start_time.length > 0 &&
    values.end_time.length > 0 &&
    values.date.length > 0 &&
    values.address.length > 0 &&
    values.class_id.length > 0 &&
    values.instructor_id.length > 0 &&
    values.total_seats > 0 &&
    values.class_qty > 0 &&
    moment(values.start_time, "HH:mm").diff(moment(values.end_time, "HH:mm")) <
      0;
  const isValidFormForMultiple = (values) =>
    values.name.length > 0 &&
    Object.keys(appointmentRows).every((key) => {
      const item = appointmentRows[key];
      return (
        item.dates.length > 0 &&
        item.start_time.length > 0 &&
        item.end_time.length > 0 &&
        moment(item.start_time, "HH:mm").diff(moment(item.end_time, "HH:mm")) <
          0 &&
        item.hexCode !== ""
      );
    }) &&
    values.address.length > 0 &&
    values.class_id.length > 0 &&
    values.instructor_id.length > 0 &&
    values.total_seats > 0 &&
    values.class_qty > 0;
  useEffect(() => {
    if (isEditMode) {
      const editDate = moment(formik.values.date).format("YYYY-MM-DD");
      setSelectedDates([editDate]);
    } else {
      setSelectedDates(
        formik.values.date.map((date) => moment(date).format("YYYY-MM-DD"))
      );
    }
  }, [formik.values.date]);

  useEffect(() => {
    const selectedStds = formik.values.student
      .map((student) => {
        if (student.status === "ACCEPTED")
          return {
            value: student.id,
            label: student.name,
            is_active: true,
            status: student.status,
            reserved_on: student.reserved_on,
          };
        else return;
      })
      .filter(Boolean);
    setSelectedStudents(selectedStds);
  }, [formik.values.student]);

  const handleSelectStudents = (selectedOptions, actionMeta) => {
    if (formik.values.total_seats >= selectedOptions.length)
      setSelectedStudents(selectedOptions);

    if (isEditMode) {
      if (actionMeta.action === "remove-value")
        removeStudent(actionMeta.removedValue);
      else addStudent(actionMeta.option);
    }
  };
  const handleDateChangeEdit = (selectedOptions) => {
    if (isEditMode) {
      let formattedDates = [
        new Date(selectedOptions[selectedOptions.length - 1]),
      ];
      setSelectedDates(formattedDates);
    } else {
      const formattedDates = selectedOptions.map(
        (timestamp) => new Date(timestamp)
      );
      setSelectedDates(formattedDates);
    }
  };

  // const [appointmentRows, setAppointmentRows] = useState({});

  const [appointmentRows, setAppointmentRows] = useState({
    defaultRow: {
      dates: [],
      start_time: "",
      end_time: "",
      hexCode: "",
    },
  });

  const submitForm = async () => {
    //update appoinment
    if (isEditMode) {
      const totalStudents = selectedStudents.map((student) => ({
        id: student.value,
        name: student.label,
        status: student.status,
        reserved_on: student.reserved_on,
        is_active: true,
      }));

      await formik.setFieldValue("student", totalStudents);

      if (totalStudents.length > 0)
        await formik.setFieldValue("has_student", true);

      const dates = [];

      selectedDates.forEach((date) => {
        dates.push(moment(date).format("YYYY-MM-DD"));
      });

      await formik.setFieldValue("date", dates);
      if (!isValidForm(formik.values)) {
        notify("ERROR", "top-right", "Check the missing fields");
        return;
      }
      if (isEditMode) {
        const stdsToRemove = studentsToRemove.filter((studentId) => {
          const matchingStudent = formik.values.student.find(
            (student) =>
              student.id === studentId && student.status === "ACCEPTED"
          );
          return !!matchingStudent;
        });
        const stdsToAdd = studentsToAdd.filter((studentAdded) => {
          const existingStudent = formik.values.student.find(
            (student) => student.id === studentAdded.id
          );
          return !existingStudent || existingStudent.status === "CANCELLED";
        });
        setStudentsToAdd(stdsToAdd);
        setStudentsToRemove(stdsToRemove);
      }
      formik.handleSubmit();
      return;
    }

    //create appoinment
    let responseTest = true;
    if (Object.keys(appointmentRows).length > 0) {
      if (!isValidFormForMultiple(formik.values)) {
        notify("ERROR", "top-right", "Check the missing fields");
        return;
      }
      for (let key in appointmentRows) {
        let tempAppointmentRow = appointmentRows[key];
        const totalStudents = selectedStudents.map((student) => ({
          id: student.value,
          name: student.label,
          status: student.status,
          reserved_on: student.reserved_on,
          is_active: true,
        }));

        // await formik.setFieldValue('student', totalStudents);

        // if (totalStudents.length > 0) await formik.setFieldValue('has_student', true);

        const dates = [];
        for (let i = 0; i < tempAppointmentRow.dates.length; i++) {
          const date = tempAppointmentRow.dates[i];
          dates.push(moment(date).format("YYYY-MM-DD"));
        }
        // await formik.setFieldValue('date', dates);
        // await formik.setFieldValue('start_time', tempAppointmentRow.start_time);
        // await formik.setFieldValue('end_time', tempAppointmentRow.end_time);
        const instructor = instructors.find(
          (instructor) => instructor._id === formik.values.instructor_id
        );
        // await formik.setFieldValue('instructor_name', instructor.name);
        let payload = {
          ...formik.values,
          date: dates,
          start_time: tempAppointmentRow.start_time,
          end_time: tempAppointmentRow.end_time,
          instructor_name: instructor.name,
          student: totalStudents,
          has_student: totalStudents.length > 0 ? true : false,
          hexCode: tempAppointmentRow.hexCode,
        };
        const response = await createAppointment(payload);
        if (!response.data.data && !response.data.data.appointment_record) {
          responseTest = false;
        }
      }
      if (responseTest) {
        notify("SUCCESS", "top-right", "Appointment created successfully!");
      }
      if (responseTest) {
        const allAppointments = await viewAllAppointments();
        setAppointments(allAppointments);
      }
      closeModal();
      return;
    } else {
      notify("ERROR", "top-right", "atleast add one row of appointment");
    }
  };

  const removeStudent = (removedStudent) => {
    if (!studentsToRemove.find((student) => student === removedStudent.value)) {
      setStudentsToRemove((prevRemoved) => [
        ...prevRemoved,
        removedStudent.value,
      ]);
    }
    if (studentsToAdd.find((student) => student.id === removedStudent.value)) {
      setStudentsToAdd((prevAdded) =>
        prevAdded.filter((student) => student.id !== removedStudent.value)
      );
    }
  };

  const addStudent = (addedStudent) => {
    if (!studentsToAdd.find((student) => student.id === addedStudent.value)) {
      setStudentsToAdd((prevAdded) => [
        ...prevAdded,
        { id: addedStudent.value, name: addedStudent.label },
      ]);
    }
    if (studentsToRemove.find((student) => student === addedStudent.value)) {
      setStudentsToRemove((prevRemoved) =>
        prevRemoved.filter((student) => student !== addedStudent.value)
      );
    }
  };
  const generateRandomId = () => {
    const randomNumber = Math.floor(Math.random() * 1000);
    const timestamp = new Date().getTime();
    const randomId = timestamp.toString() + randomNumber.toString();
    return randomId;
  };

  // Example usage

  const handleDateChange = (id, selectedOptions) => {
    if (isEditMode) {
      let formattedDates = [
        new Date(selectedOptions[selectedOptions.length - 1]),
      ];
      setSelectedDates(formattedDates);
    } else {
      let tempAppointmentRow = appointmentRows[id];
      const formattedDates = selectedOptions.map(
        (timestamp) => new Date(timestamp)
      );
      tempAppointmentRow = {
        ...tempAppointmentRow,
        dates: formattedDates,
      };
      setAppointmentRows({ ...appointmentRows, [id]: tempAppointmentRow });
      // setSelectedDates(formattedDates);
    }
  };
  const calculateEndTime = (startTime, minutesToAdd) => {
    var parts = startTime.split(":");
    var startHours = parseInt(parts[0]);
    var startMinutes = parseInt(parts[1]);
    var endMinutes = (startMinutes + minutesToAdd) % 60;
    var hoursOverflow = Math.floor((startMinutes + minutesToAdd) / 60);
    var endHours = (startHours + hoursOverflow) % 24;
    var endHoursString = String(endHours).padStart(2, "0");
    var endMinutesString = String(endMinutes).padStart(2, "0");
    return endHoursString + ":" + endMinutesString;
  };
  const handleStartTimeChange = (id, selectedTime) => {
    let tempAppointmentRow = appointmentRows[id];

    let duration = 0;
    classTypes.forEach((item) => {
      if (item._id === formik.values.class_id) {
        duration =
          item.duration *
          (formik.values.class_qty ? parseInt(formik.values.class_qty) : 0);
      }
    });
    const endTime = calculateEndTime(selectedTime.target.value, duration);
    tempAppointmentRow = {
      ...tempAppointmentRow,
      end_time: endTime,
      start_time: selectedTime.target.value,
    };
    setAppointmentRows({ ...appointmentRows, [id]: tempAppointmentRow });
  };
  const handleEndTimeChange = (id, selectedTime) => {
    let tempAppointmentRow = appointmentRows[id];
    tempAppointmentRow = {
      ...tempAppointmentRow,
      end_time: selectedTime.target.value,
    };
    setAppointmentRows({ ...appointmentRows, [id]: tempAppointmentRow });
  };
  const handleAddDates = () => {
    const id = generateRandomId();
    let tempAppointmentRows = { ...appointmentRows };
    tempAppointmentRows[id] = {
      dates: [],
      start_time: "",
      end_time: "",
    };
    setAppointmentRows(tempAppointmentRows);
  };
  const handleColorChange = (id, selectedColor) => {
    let tempAppointmentRow = appointmentRows[id];
    tempAppointmentRow = {
      ...tempAppointmentRow,
      hexCode: selectedColor.target.value,
    };
    setAppointmentRows({ ...appointmentRows, [id]: tempAppointmentRow });
  };

  const removeAppointment = (id) => {
    let newData = { ...appointmentRows };
    delete newData[id];
    setAppointmentRows(newData);
  };
  useEffect(() => {
    let tempAppointmentRows = { ...appointmentRows };
    Object.keys(tempAppointmentRows).map((key) => {
      tempAppointmentRows[key] = {
        dates: [],
        start_time: "",
        end_time: "",
      };
    });
    setAppointmentRows(tempAppointmentRows);
  }, [formik.values.class_qty, formik.values.class_id]);

  const AppointmentModalComponent = () => (
    <>
      {formik.values && (
        <div className="space-y-4">
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="form-input w-full"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                Instructor
              </label>
              <select
                id="instructor_id"
                name="instructor_id"
                className="form-select w-full"
                value={formik.values.instructor_id}
                onChange={formik.handleChange}
                disabled={isInstructor && isInstructor ? true : false}
              >
                <option disabled value="">
                  - Select Instructor -
                </option>
                {instructors &&
                  instructors.map((instructor) => (
                    <option key={instructor._id} value={instructor._id}>
                      {instructor.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                Class Type
              </label>
              <select
                id="class_id"
                name="class_id"
                className="form-select w-full"
                value={formik.values.class_id}
                disabled={isEditMode}
                onChange={formik.handleChange}
              >
                <option disabled value="">
                  - Select Class -
                </option>
                {classTypes &&
                  classTypes.map((classType) => (
                    <option key={classType._id} value={classType._id}>
                      {classType.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                No. of Classes
              </label>
              <input
                id="class_qty"
                name="class_qty"
                type="number"
                className="form-input w-full"
                value={formik.values.class_qty}
                onChange={formik.handleChange}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                No. of Seats
              </label>
              <input
                id="total_seats"
                name="total_seats"
                type="number"
                className="form-input w-full"
                value={formik.values.total_seats}
                disabled={isEditMode}
                onChange={formik.handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="form-label" htmlFor="grid-password">
                Address
              </label>
              <input
                id="address"
                name="address"
                type="text"
                className="form-input w-full"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {isEditMode && (
            <>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <label className="form-label" htmlFor="grid-password">
                    Start Time
                  </label>
                  <input
                    id="start_time"
                    name="start_time"
                    type="time"
                    className="form-input w-full"
                    value={formik.values.start_time}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="space-y-1">
                  <label className="form-label" htmlFor="grid-password">
                    End Time
                  </label>
                  <input
                    id="end_time"
                    name="end_time"
                    type="time"
                    className="form-input w-full"
                    value={formik.values.end_time}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label className="form-label" htmlFor="grid-password">
                  Date(s)
                </label>
                <DatePicker
                  id="date"
                  name="date"
                  inputClass="form-input w-full"
                  value={selectedDates}
                  onChange={handleDateChangeEdit}
                  placeholderText="Select dates"
                  format="YYYY-MM-DD"
                  multiple
                  plugins={[<DatePanel />]}
                />
              </div>
              <div className="space-y-1 col-span-3">
                <label className="form-label" htmlFor="grid-password">
                  Color
                </label>
                <select
                  id="hexCode"
                  name="hexCode"
                  className="form-select w-full text-white"
                  style={{ backgroundColor: formik.values.hexCode }}
                  value={formik.values.hexCode}
                  onChange={formik.handleChange}
                >
                  <option value="" hidden>
                    - Select Color -
                  </option>
                  {listColorCode &&
                    listColorCode.map((color) => (
                      <option
                        key={color.value}
                        value={color.value}
                        style={{ backgroundColor: color.value }}
                      >
                        {color.name}
                      </option>
                    ))}
                </select>
              </div>
            </>
          )}
          {/* {!isEditMode &&
                        <button onClick={handleAddDates} type="button"
                                className="btn btn-success px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                 stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                            </svg>
                        </button>
                    }*/}
          {!isEditMode &&
            Object.keys(appointmentRows).length > 0 &&
            Object.keys(appointmentRows).map((key, index) => {
              return (
                <div
                  key={key}
                  className="grid md:grid-cols-9 grid-cols-8 items-end gap-4 md:border-none border md:rounded-none rounded md:p-0 p-3"
                >
                  <div className="md:col-span-2 col-span-4 space-y-1">
                    <label className="form-label" htmlFor="grid-password">
                      Date(s)
                    </label>
                    <DatePicker
                      id="date"
                      name="date"
                      inputClass="form-input w-full"
                      value={appointmentRows[key].dates}
                      onChange={(selectedOptions) =>
                        handleDateChange(key, selectedOptions)
                      }
                      placeholderText="Select dates"
                      format="YYYY-MM-DD"
                      multiple
                      plugins={[<DatePanel />]}
                    />
                  </div>
                  <div className="md:col-span-2 col-span-4 space-y-1">
                    <label className="form-label" htmlFor="grid-password">
                      Start Time
                    </label>
                    <input
                      id="start_time"
                      name="start_time"
                      type="time"
                      className="form-input w-full"
                      value={appointmentRows[key].start_time}
                      onChange={(selectedTime) =>
                        handleStartTimeChange(key, selectedTime)
                      }
                    />
                  </div>
                  <div className="md:col-span-2 col-span-4 space-y-1">
                    <label className="form-label" htmlFor="grid-password">
                      End Time
                    </label>
                    <input
                      id="end_time"
                      name="end_time"
                      type="time"
                      className="form-input w-full"
                      value={appointmentRows[key].end_time}
                      disabled={true}
                      onChange={(selectedTime) =>
                        handleEndTimeChange(key, selectedTime)
                      }
                    />
                  </div>
                  <div className="md:col-span-2 col-span-4 space-y-1">
                    <label className="form-label" htmlFor="grid-password">
                      Color
                    </label>
                    <select
                      id="hexCode"
                      name="hexCode"
                      className="form-select w-full text-white"
                      style={{ backgroundColor: appointmentRows[key].hexCode }}
                      value={appointmentRows[key].hexCode}
                      onChange={(selectedOptions) =>
                        handleColorChange(key, selectedOptions)
                      }
                    >
                      <option value="" hidden>
                        - Select Color -
                      </option>
                      {listColorCode &&
                        listColorCode.map((color) => (
                          <option
                            key={color.value}
                            value={color.value}
                            style={{ backgroundColor: color.value }}
                          >
                            {color.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="md:col-span-1 col-span-8 self-end pb-1.5 ">
                    {index > 0 ? (
                      <svg
                        onClick={() => removeAppointment(key)}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-7 h-7 stroke-red-600 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {!isEditMode && (
            <div className="mt-6 text-green-500 text-center font-normal text-base">
              <span
                className="cursor-pointer hover:underline underline-offset-2"
                onClick={handleAddDates}
              >
                + Add More
              </span>
            </div>
          )}

          <div className="space-y-1">
            <label className="form-label" htmlFor="grid-password">
              Attending Students
            </label>
            <div className="flex space-x-1">
              {students &&
                students.map((student) => (
                  <div
                    key={student.student_id}
                    onClick={(event) => {
                      navigate(
                        `/school/students/${student.student_id}?studentDetailTab=General Info`
                      );
                    }}
                    className=" flex p-1 rounded-md bg-gray-200 gap-0.5 cursor-pointer hover:opacity-50 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 stroke-black cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <label
                      className="cursor-pointer"
                      htmlFor={student.student_id}
                    >
                      {student.student_name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="space-y-1">
            <label className="form-label" htmlFor="grid-password">
              Students
            </label>
            <Select
              isDisabled={isStudent && isStudent ? true : false}
              id="student"
              name="student"
              className="w-full h-12"
              isMulti
              options={
                students &&
                students.map((student) => ({
                  value: student.student_id,
                  label: student.student_name,
                  is_active: true,
                  status: "ACCEPTED",
                  reserved_on: moment().format("YYYY-MM-DD"),
                }))
              }
              value={selectedStudents}
              onChange={handleSelectStudents}
            />
          </div>
          {isEditMode &&
            formik.values.created_by === "SCHOOL" &&
            moment(selectedDate).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD") && (
              <>
                <button
                  onClick={() => {
                    deleteAppointment(selectedAppointmentId);
                    closeModal();
                  }}
                  type="button"
                  className="w-full h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm"
                >
                  Cancel Appointment
                </button>
                <div className="space-y-1">
                  <label className="form-label" htmlFor="grid-password">
                    Cancel Description
                  </label>
                  <input
                    id="cancel_description"
                    name="cancel_description"
                    type="text"
                    className="form-input w-full"
                    value={formik.values.cancel_description}
                    onChange={formik.handleChange}
                  />
                </div>
              </>
            )}
        </div>
      )}
    </>
  );

  return (
    <>
      <Modal
        isOpen={isAppointmentModalOpen}
        closeModal={closeModal}
        title={`${isEditMode ? "Edit" : "Book"} Appointment`}
        component={AppointmentModalComponent}
        showComponent={true}
        onConfirmClick={submitForm}
        selectedDate={selectedDate}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default AppointmentModal;
