//Libraries
import axios from "axios";

//Components
import notify from "../helpers/raiseToast";

//Helpers
import logout from "../helpers/logout";

//Base Url
const ApiUrl = process.env.REACT_APP_API_URL + "/student";

const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
//Interceptors to attach tokens dynamically.
//Use axios instance for requests to attach these headers.
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["x-access-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const studentLogin = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}/login`, body);
    const token = response.data;
    localStorage.setItem("id", token.id);
    localStorage.setItem("user_type", token.user_type);
    localStorage.setItem("email", token.email);
    localStorage.setItem("name", token.name);
    localStorage.setItem("avatar", token.avatar);
    localStorage.setItem("accessToken", token.accessToken);
    return true;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const studentRegister = async (body) => {
  try {
    await axios.post(`${ApiUrl}/signup`, body);
    notify("SUCCESS", "top-right", "Registered successfully!");
    return true;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const invitationStudentRegister = async (body) => {
  try {
    await axiosInstance.put("/signup-invite", body);
    notify("SUCCESS", "top-right", "Registered successfully!");
    return true;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const getStudentRequests = async () => {
  try {
    const response = await axiosInstance.get("/view-request");
    return response.data;
  } catch (error) {
    return;
  }
};

export const changeRequestStatus = async (body) => {
  try {
    await axiosInstance.post("change-request-status", body);
    if (body.status === "YES") {
      notify("SUCCESS", "top-right", "School request accepted successfully!");
    } else {
      notify("ERROR", "top-right", "School request rejected!");
    }
    return;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return;
  }
};

export const getStudentLessonPlans = async () => {
  try {
    const response = await axiosInstance.get("/view/lessons");
    return response.data;
  } catch (error) {
    return;
  }
};

export const getStudentLessonPlanById = async (lessonId) => {
  try {
    const response = await axiosInstance.get(`/view/lesson/${lessonId}`);
    return response.data;
  } catch (error) {
    return;
  }
};

export const SignLesson = async (lessonId, body) => {
  try {
    await axiosInstance.put(`/lesson/sign/${lessonId}`, body);
    notify("SUCCESS", "top-right", "Lesson signed successfully!");
    return true;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const getAllClassTypes = async () => {
  try {
    const response = await axiosInstance.get(`/view/classes/all`);
    return response.data;
  } catch (error) {
    return;
  }
};

export const viewAllAppointments = async (schoolId) => {
  try {
    const response = await axiosInstance.get(
      `/view/appointments/all/school/${schoolId}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export const viewMySchool = async () => {
  try {
    const response = await axiosInstance.get("/view-my-school/");
    return response.data;
  } catch (error) {
    return;
  }
};

export const bookStudentAppointment = async (appointmentId) => {
  try {
    const response = await axiosInstance.put(
      `/appointment/book/${appointmentId}`
    );
    console.log(response.data);
    if (response.data && response.data.updated_appointment)
      notify("SUCCESS", "top-right", "Appointment booked successfully!");
    else
      notify(
        "ERROR",
        "top-right",
        "Appointment booking failed! Insufficient balance."
      );
    return response.data;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const cancelAppointmentById = async (
  appointmentId,
  cancellationReason
) => {
  try {
    const body = { cancellation_reason: cancellationReason };
    await axiosInstance.put(`/appointment/cancel/${appointmentId}`, body);
    notify("SUCCESS", "top-right", "Appointment cancelled successfully!");
    return true;
  } catch (error) {
    notify(
      "ERROR",
      "top-right",
      "Oops! Something went wrong. Please try again."
    );
    return false;
  }
};

export const getStudentInfo = async () => {
  try {
    const response = await axiosInstance.get("/getstudent");
    return response.data;
  } catch (error) {
    return;
  }
};

export const updateStudent = async (body) => {
  try {
    const response = await axiosInstance.put("profile/update", body);
    notify("SUCCESS", "top-right", "Successfully updated");
    return response.data;
  } catch (error) {
    notify("ERROR", "top-right", "Invalid data");
    return;
  }
};
export const updateStudentPassword = async (body) => {
  try {
    const response = await axiosInstance.put("profile/updatePassword", body);
    notify("SUCCESS", "top-right", "Password updated");
    return response.data;
  } catch (error) {
    notify("ERROR", "top-right", "Invalid password");
    return;
  }
};
export const updateStudentProfileImage = async (body) => {
  try {
    const response = await axiosInstance.post(
      "profile/updateProfilePicture",
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    notify("SUCCESS", "top-right", "Profile image updated");
    console.log(response.data.data.updated_record);
    localStorage.setItem(
      "avatar",
      `${process.env.REACT_APP_API_URL}/uploads/${response.data.data.updated_record.avatar}`
    );
    return response.data;
  } catch (error) {
    notify("ERROR", "top-right", "Invalid image");
    return;
  }
};

export const getInstructors = async () => {
  try {
    const response = await axiosInstance.get("school/instructors");
    return response.data;
  } catch (error) {
    return;
  }
};

export const getSchool = async () => {
  try {
    const response = await axiosInstance.get("getSchool");
    return response.data;
  } catch (error) {
    return;
  }
};

export const getClassDataOnIds = async (body) => {
  try {
    const response = await axiosInstance.post(`/getAllClassesOnIds`, body);
    return response.data;
  } catch (error) {
    return;
  }
};

export const getDashboardDataService = async () => {
  try {
    const response = await axiosInstance.get("dashboard-panel");
    return response.data;
  } catch (error) {
    return;
  }
};

export const getTransactionStudent = async () => {
  try {
    const response = await axiosInstance.get(`/getTransactionsForStudent`);
    return response.data;
  } catch (error) {
    return;
  }
};
export const getCreditHistoryDataOnUserId = async () => {
  try {
    const response = await axiosInstance.get("creditHistoryStudent");
    return response.data;
  } catch (error) {
    return;
  }
};

export const getStudentCreditHistory = async () => {
  try {
    const response = await axiosInstance.get(`getStudentCreditHistory`);
    return response.data;
  } catch (error) {
    return;
  }
};
