import React from "react";
import drivePro from '../../../assets/drivePro1.jpeg';

const TheoryLecture = () => {

    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center gap-4">
                    <div
                        className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                        </svg>
                    </div>
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Control Questions for the practical
                        test</h3>
                </div>
                <div className="flex justify-center border shadow-sm p-5 font-sans">
                    <div className="space-y-4 text-center md:max-w-[700px] w-full">
                        <h2 className="text-3xl text-gray-600 font-semibold">Check</h2>
                        <h4 className="text-xl text-gray-600 font-semibold">You must be able to check whether the following conditions for the control device are met</h4>
                        <div className="flex items-center justify-center">
                            <img src={drivePro} alt="" className="w-96 h-60 object-contain"/>
                        </div>
                        <p className="text-gray-500 font-semibold">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                        </p>
                        <h4 className="text-xl text-gray-600 font-semibold">You must be able to check whether the following conditions for the control device are met</h4>
                        <div className="flex items-center justify-center">
                            <img src={drivePro} alt="" className="w-96 h-60 object-contain"/>
                        </div>
                        <p className="text-gray-500 font-semibold">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TheoryLecture;