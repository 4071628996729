//Libraries
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";

//Helpers
import { checkScreenSize } from "../../helpers/screenSize";

//Styles
import "./calendar.css";

const Calendar = ({
  setIsAppointmentModalOpen,
  setIsEditMode,
  events,
  setSelectedAppointmentId,
  setSelectedDate = () => {},
  removeAddAppointment,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [modifiedEvent, setModifiedEvent] = useState(events);
  useEffect(() => {
    const temp = events.map((item, key) => ({
      ...item,
      // color: item.fullyBooked || item.bookedByUser ? '#f6ad55' : '#48bb78',
      color: item.hexCode,
      dotColor: item.fullyBooked || item.bookedByUser ? "#f6ad55" : "#48bb78",
      // backgroundColor: item.fullyBooked || item.bookedByUser ? '#f6ad55' : '#48bb78',
      backgroundColor: item.hexCode,
      textColor: "#fff",
      borderColor: "none",
      display: "block",
    }));
    setModifiedEvent(temp);
  }, [events]);
  const mobileHeaderToolbar = {
    left:
      localStorage.getItem("user_type") !== "student"
        ? !removeAddAppointment
          ? "appointmentButton"
          : ""
        : "",
    center: "prev title next",
    right:
      localStorage.getItem("user_type") !== "student"
        ? "listWeek listDay"
        : "listWeek listDay",
  };
  const desktopHeaderToolbar = {
    left:
      localStorage.getItem("user_type") !== "student"
        ? !removeAddAppointment
          ? "appointmentButton"
          : ""
        : "",
    center: "prev title next",
    right:
      localStorage.getItem("user_type") !== "student"
        ? "today dayGridMonth listMonth listWeek listDay"
        : "dayGridMonth listMonth listWeek listDay",
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkScreenSize(768));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openAppointmentModal = (event = undefined) => {
    if (event) {
      setIsEditMode(true);
      setSelectedAppointmentId(event.extendedProps.appointment_id);
      setSelectedDate(moment(event.start).format("DD-MMMM-YYYY"));
    } else setIsEditMode(false);
    setIsAppointmentModalOpen(true);
  };

  const calculateMins = (startDate, endDate) => {
    let startTimeObj = new Date(startDate);
    let endTimeObj = new Date(endDate);
    let differenceMs = endTimeObj.getTime() - startTimeObj.getTime();
    return Math.floor(differenceMs / (1000 * 60));
  };

  const eventRender = ({ event }) => {
    let className = "";
    if (event.extendedProps.bookedByUser) className = "fully-booked-event";
    else if (event.extendedProps.fullyBooked) className = "fully-booked-event";
    else className = "available-event";
    return (
      <div
        className={className + "flex flex-col event-primary cursor-pointer p-2"}
        style={{ backgroundColor: event.backgroundColor }}
      >
        <div>
          <p className="fc-event-time underline">
            {event.extendedProps.heading ??
              "Driving lesson " +
                calculateMins(event.start, event.end) +
                " mins"}
          </p>
        </div>
        <div>
          <p className="fc-event-title flex text-white ">{event.title} </p>
        </div>
        <div className="fc-event-title flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 stroke-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          {event.extendedProps.instructor_name}
        </div>
        <div className="fc-event-title flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 stroke-black"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
            ></path>
          </svg>
          {moment(event.start, "HH:mm A").format("HH:mm A") +
            " - " +
            moment(event.end, "HH:mm A").format("HH:mm A")}
        </div>
        <br />
        <div className="fc-event-title flex space-x-0.5 mb-2 items-center">
          <div className=" text-white">
            {
              <span
                className={`fc-event-title text-white font-semibold p-2 rounded-md ${
                  event.extendedProps.fullyBooked
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
              >
                {event.extendedProps.fullyBooked ? "Fully Booked" : "Open"}
              </span>
            }
          </div>
          {event.extendedProps.role !== "student" && (
            <div className=" text-white">
              {
                <span
                  className={`fc-event-title text-white font-semibold p-2 rounded-md border border-black`}
                >
                  {event.extendedProps.seats_booked} /{" "}
                  {event.extendedProps.total_seats} Seats Booked
                </span>
              }
            </div>
          )}
        </div>
      </div>
    );
  };
  // <div
  //     className="fc-event-time">{moment(event.start, 'HH:mm A').format('HH:mm A')} - {moment(event.end, 'HH:mm A').format('HH:mm A')}</div>
  // <div className="fc-event-title">{event.extendedProps.instructor_name + ":"} {event.title}</div>

  return (
    <>
      {modifiedEvent && (
        <FullCalendar
          plugins={[listPlugin, dayGridPlugin, timeGridPlugin]}
          selectable={true}
          select={(info) => {
            alert("Selected: " + info.startStr + " to " + info.endStr);
          }}
          buttonText={{
            dayGridMonth: "Month Grid",
            today: "Today",
            month: "Month List",
            week: "Week",
            day: "Day",
          }}
          headerToolbar={isMobile ? mobileHeaderToolbar : desktopHeaderToolbar}
          initialView={"listDay"}
          customButtons={{
            appointmentButton: {
              text: "Add Appointment",
              click: () => openAppointmentModal(),
            },
          }}
          slotDuration="00:30:00"
          minTime="00:00:00"
          maxTime="24:00:00"
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          eventClick={(event) => openAppointmentModal(event.event)}
          events={modifiedEvent}
          eventContent={eventRender}
        />
      )}
    </>
  );
};

export default Calendar;
