import React, { useEffect, useState } from "react";
import {
  getCreditHistoryDataOnUserId,
  getDashboardDataService,
} from "../../../services/studentService";
import { useNavigate } from "react-router-dom";
import {
  calaulateDayAndDate,
  calculateAgeInTime,
} from "../../../helpers/utils";

const StudentDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  const navigate = useNavigate();

  const openP23Form = () => {
    window.open(
      "https://mobergs.dk/wp-content/uploads/2022/07/P23.pdf",
      "_blank",
      "noopener,noreferrer"
    );
  };
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboardDataResponse = await getDashboardDataService();
        setDashboardData(dashboardDataResponse);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDashboardData();
  }, []);
  return (
    <div>
      <div className="lg:hidden">
        <h3 className="text-gray-600 text-2xl font-semibold">Welcome</h3>
        <h2 className="text-gray-600 text-3xl font-semibold">
          {localStorage.getItem("name")}!
        </h2>
      </div>
      <div className="md:space-y-6 space-y-4 md:mb-0 mb-14">
        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold lg:block hidden">
          Welcome,{localStorage.getItem("name")}!
        </h3>
        <div className="space-y-4 md:hidden">
          {dashboardData?.todayAppointments &&
          dashboardData?.todayAppointments.length === 0 ? (
            <p className="text-sm text-red-500 italic font-semibold underline underline-offset-2">
              No Appointments!
            </p>
          ) : (
            <p className="text-sm text-gray-500 italic font-semibold underline underline-offset-2">
              Today's Appointment!
            </p>
          )}
          {dashboardData?.todayAppointments &&
            dashboardData?.todayAppointments.map((item, key) => (
              <div
                onClick={() => {
                  navigate("/student/appointment");
                }}
                key={key}
                className="w-full shadow-lg rounded-lg px-5 py-3 flex items-center gap-4 text-base bg-gray-50 relative"
              >
                <div className="w-16 h-16 bg-white shadow-md rounded-lg flex flex-col items-center justify-center">
                  <span className="text-xs text-green-500 font-semibold">
                    {calaulateDayAndDate(item?.date)?.day}
                  </span>
                  <h4 className="text-4xl text-gray-500 font-semibold">
                    {calaulateDayAndDate(item?.date)?.formattedDate}
                  </h4>
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-sm text-gray-500 font-semibold">
                    Appointment Name:{item?.name}
                  </span>
                  <span className="text-xs text-gray-500 font-semibold">
                    Start Time:{" "}
                    <span className="font-extrabold">{item?.start_time}</span> |
                    End Time:{" "}
                    <span className="font-extrabold">{item?.end_time}</span>
                  </span>
                </div>
                {/*<span className="absolute right-5 top-3 text-xs text-gray-400">{calculateAgeInTime(item?.date).hours + " hrs " + calculateAgeInTime(item?.date).minutes + " mins " + calculateAgeInTime(item?.date).seconds + " secs"}</span>*/}
              </div>
            ))}
        </div>
        <div className="px-16 py-2 md:hidden">
          <div className="border-b-2 border-b-gray-300"></div>
        </div>
        <div className="flex space-x-2">
          <div
            onClick={() => {
              navigate("/student/lesson-plan");
            }}
            className="flex items-center gap-2 border rounded-md p-4 hover:bg-gray-50 hover:shadow duration-300 cursor-pointer xl:w-1/2 md:w-1/2 w-full"
          >
            <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8 stroke-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                />
              </svg>
            </div>
            <h4 className="font-semibold text-xl text-gray-600">
              Lesson <span className="text-blue-500">Plan</span>
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 stroke-2 stroke-blue-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </div>
          <div
            onClick={() => {
              openP23Form();
            }}
            className="flex items-center gap-2 border rounded-md p-4 hover:bg-gray-50 hover:shadow duration-300 cursor-pointer xl:w-1/2 md:w-1/2 w-full"
          >
            <div className="flex items-center justify-center w-14 h-14 p-3 bg-blue-100 rounded-md">
              <svg
                fill="none"
                height="800px"
                width="800px"
                viewBox="0 0 482.14 482.14"
                className="fill-blue-600"
              >
                <g>
                  <path
                    d="M302.598,0H108.967C80.661,0,57.652,23.025,57.652,51.315v379.509c0,28.289,23.009,51.315,51.315,51.315h264.205
		c28.274,0,51.315-23.026,51.315-51.315V121.449L302.598,0z M373.172,450.698H108.967c-10.97,0-19.891-8.905-19.891-19.874V51.315
		c0-10.953,8.921-19.858,19.891-19.858l181.874-0.189v67.218c0,19.653,15.949,35.603,35.587,35.603l65.878-0.189l0.725,296.925
		C393.031,441.793,384.142,450.698,373.172,450.698z"
                  />
                  <path
                    d="M297.413,246.059h-24.634v-73.058c0-7.414-6.004-13.42-13.411-13.42h-36.626c-7.392,0-13.396,6.006-13.396,13.42v73.058
		H184.71c-4.065,0-7.723,2.458-9.299,6.225c-1.559,3.75-0.709,8.085,2.191,10.978l56.327,56.335c3.94,3.94,10.323,3.94,14.247,0
		l56.359-56.335c2.884-2.893,3.736-7.228,2.191-10.978C305.152,248.517,301.48,246.059,297.413,246.059z"
                  />
                </g>
              </svg>
            </div>
            <h4 className="font-semibold text-xl text-gray-600">
              Download <span className="text-blue-500">P23 Form</span>
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 stroke-2 stroke-blue-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </div>
        </div>
        <div className="border rounded-md md:block hidden">
          {dashboardData?.todayAppointments &&
          dashboardData?.todayAppointments.length === 0 ? (
            <div className="border-b p-4 rounded-t-md">
              <h4 className="font-semibold text-xl text-gray-600">
                No Appoinments
              </h4>
            </div>
          ) : (
            <div className="border-b p-4 rounded-t-md">
              <h4 className="font-semibold text-xl text-gray-600">
                Today's <span className="text-blue-500">Appointment</span>
              </h4>
            </div>
          )}
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                    Appointment Name
                  </td>
                  <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                    Start Time
                  </td>
                  <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                    End Time
                  </td>
                </tr>
              </thead>
              <tbody id="table-body" className="divide-y divide-gray-200">
                {dashboardData?.todayAppointments &&
                dashboardData?.todayAppointments.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="px-6 py-3 text-center text-red-500"
                    >
                      No Appointment found.
                    </td>
                  </tr>
                ) : (
                  dashboardData?.todayAppointments?.map((item, key) => (
                    <tr
                      key={key}
                      onClick={() => {
                        navigate("/student/appointment");
                      }}
                    >
                      <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                        {item.name}
                      </td>
                      <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                        {item.start_time}
                      </td>
                      <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                        {item.end_time}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
